<script>
import TablePage from '@/found/components/table_page';
import Modal from '@/found/components/modal';
import DetailForm from '../form/index';

export default {
  extends: TablePage,
  name: 'main_list',
  components: {
    Modal,
    DetailForm,
  },
  data() {
    return {
      // requestType: 'GET',
      params: {
        type: 'tasting',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('main_list');
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      if (['view'].includes(val.code)) {
        this.formConfig = {};
        this.formName = 'DetailForm';
        if (val.code === 'view') {
          this.modalConfig.title = '兑付明细';
        }
        this.modalConfig.width = '70%';
        this.modalConfig.height = '600px';
        this.formConfig = {
          type: val.code,
          id: row.id || '',
          code: val.code || '',
        };
        this.openFull();
      }
    },
  },
};
</script>
