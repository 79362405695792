import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import { re } from 'mathjs';
import CashDetailList from './components/cap_scan_list.vue';
import table from '../../quarter-data/table';

formCreate.component('CashDetailList', CashDetailList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cash_detail_form_detail',
    };
  },
  async created() {
    await this.getFormRule('cash_detail_form_detail');
  },
  methods: {
    // 设置规则
    setRule(item) {
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;
      if (type === 'view') {
        console.log(1111);

        request.get('/cps/v1/fxhTerminalInterestRecord/findMobileById', {
          id,
        }).then((res) => {
          if (res.success) {
            console.log(res, 'res');
            const { result } = res;
            this.setValue({
              ...result,
              files: (result.files || []).map((el) => el.url),
              CashDetailList: {
                tableData: result.scanBarcodeVoList,
              },
            });
          }
        });
      }
    },
  },
};
