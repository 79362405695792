<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  name: 'cash_detail_list',
  components: {},
  props: {
    value: Object,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('cash_detail_list');
    this.configs.pagination = false;
  },
  watch: {
    value: {
      handler(val) {
        this.tableData = val.tableData;
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
